@charset "UTF-8";
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

@font-face {
  font-family: 'AC';
  font-weight: 400;
  src: url("../../assets/fonts/ACBK.woff2") format("woff2"); }

@font-face {
  font-family: 'AC';
  font-weight: 500;
  src: url("../../assets/fonts/ACM.woff2") format("woff2"); }

@font-face {
  font-family: 'AC';
  font-weight: 700;
  src: url("../../assets/fonts/ACBL.woff2") format("woff2"); }

html {
  font-family: 'AC', sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: #1C1921;
  font-size: 45%;
  overflow-x: hidden; }
  @media (max-width: 75em) {
    html {
      font-size: 40%; } }
  @media (max-width: 56.25em) {
    html {
      font-size: 37.5%; } }

body, html {
  overflow-x: hidden;
  scroll-behavior: smooth; }

a {
  text-decoration: none;
  color: inherit; }

.hidden {
  visibility: hidden; }

.text-center {
  text-align: center !important; }

.success, .error {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 75vh; }
  .success__text, .error__text {
    color: white;
    font-size: 5rem;
    max-width: 960px; }
  .success__img, .error__img {
    margin: 5rem auto;
    height: 35rem; }

.error__text {
  color: #EF5C65; }

.success__text {
  color: #EEAD7C; }

.error__img {
  height: 35rem; }

.loader {
  width: 250px;
  height: 250px;
  border: 5px solid #312D38;
  border-top: 5px solid #625a71;
  border-radius: 50%;
  margin: auto;
  animation: rotating 2s linear infinite; }

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.bar {
  width: 1000px;
  height: 4px;
  background-image: linear-gradient(to right, #7E52EF 0%, #6371FE 26%, #FF5B5B 71%, #E9D66A 100%);
  top: 400px;
  z-index: 3;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }

.appear {
  opacity: 1 !important;
  transform: translateY(0) !important; }
  .appear img {
    opacity: 1 !important;
    transform: translateY(0) !important; }
  .appear.bigstat__stat {
    color: #FCF9FF !important;
    transform: scale(1) !important; }

.twocol {
  display: grid;
  grid-template-columns: .5fr .5fr;
  align-items: center;
  margin-bottom: 10em;
  grid-gap: 20px; }
  .twocol div {
    margin: 0; }
  @media (max-width: 56.25em) {
    .twocol {
      margin-bottom: 4em;
      grid-template-columns: 1fr;
      grid-template-rows: .5fr .5fr;
      justify-items: center; } }

.tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 11px;
  font-size: 2.1em;
  width: 95px;
  border-radius: 8px;
  top: 95px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity .2s ease, transform .2s ease; }
  .tooltip.invisible {
    transform: translateY(-3px);
    opacity: 0; }
  @media (max-width: 37.5em) {
    .tooltip {
      padding: 6px;
      font-size: 1.8em;
      width: 75px;
      top: -30px; } }

.emailsubmit {
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  padding: 11px;
  font-size: 2.1em;
  width: 95px;
  border-radius: 8px;
  top: 95px;
  opacity: 1;
  transform: translateY(0);
  transition: opacity .2s ease, transform .2s ease;
  width: max-content;
  margin: auto;
  left: 0;
  right: 0;
  top: 106%;
  font-size: .8em;
  transition: opacity .2s ease, transform .2s ease; }
  .emailsubmit.invisible {
    transform: translateY(-3px);
    opacity: 0; }

.grecaptcha-badge {
  opacity: 0;
  visibility: hidden; }

.hero {
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4rem 1rem; }
  @media (max-width: 56.25em) {
    .hero {
      padding: 4rem 0rem; } }
  .hero__logo {
    height: 12rem; }
    .hero__logo--collab {
      display: inline-flex;
      justify-content: center;
      width: 70px;
      height: 12rem; }
    .hero__logo--sponsor {
      display: inline-flex;
      height: 12rem; }
    @media (max-width: 56.25em) {
      .hero__logo {
        height: 11rem; } }
  .hero__title {
    font-size: 9.5rem;
    color: white;
    margin: 2rem auto 0rem auto;
    line-height: 1.2; }
    @media (max-width: 56.25em) {
      .hero__title {
        font-size: 7.25rem; } }
  .hero__text {
    color: rgba(252, 249, 255, 0.7);
    font-size: 3rem;
    font-weight: 500; }
    @media (max-width: 56.25em) {
      .hero__text {
        font-size: 2.5rem; } }
    .hero__text__alt {
      color: rgba(252, 249, 255, 0.7);
      font-size: 2.2rem;
      margin-top: 1rem;
      display: block; }
      @media (max-width: 56.25em) {
        .hero__text__alt {
          font-size: 1.8rem; } }
  .hero__background {
    z-index: -1;
    background: #1d1922;
    box-shadow: 0 0 100px 50px #1d1922;
    border-radius: 280px;
    width: 820px;
    padding: 30px 0; }
    @media (max-width: 56.25em) {
      .hero__background {
        width: 100%; } }
  .hero .deadline {
    grid-column-start: 1;
    grid-column-end: 3; }
  .hero__button-container {
    margin: 2rem;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 37.5em) {
      .hero__button-container {
        flex-direction: column; } }
  .hero__button {
    text-decoration: none;
    font-size: 2.75rem;
    font-weight: 400;
    padding: 1.5rem 3.3rem;
    background-color: #292b3f;
    border-radius: 5rem;
    margin: 1rem 1rem;
    transition: all .2s;
    color: white; }
    @media (max-width: 56.25em) {
      .hero__button {
        font-size: 2.5rem; } }
    @media (max-width: 37.5em) {
      .hero__button {
        font-size: 2rem; } }
    .hero__button:hover {
      background-color: #282430; }
    .hero__button.not-allowed {
      cursor: not-allowed; }
    .hero__button__cta {
      background-color: #8568eb !important;
      font-weight: 600 !important;
      text-transform: uppercase !important; }
      .hero__button__cta.orange {
        background-color: #AE9200  !important; }
        .hero__button__cta.orange:hover {
          background-color: #c8a700 !important; }
      .hero__button__cta.green {
        background-color: #00ae34  !important; }
        .hero__button__cta.green:hover {
          background-color: #00c83c !important; }
      .hero__button__cta:hover {
        background-color: #977ef1 !important; }
    .hero__button__tiny {
      color: white;
      font-size: 2em;
      text-decoration: none;
      background: #292940;
      padding: 0.7em 1em 0.7em 0.4em;
      border-radius: 2em;
      transition: background 0.5s ease;
      margin-bottom: 5em; }
      .hero__button__tiny:before {
        content: "NEW!";
        background: #676bff;
        padding: 4px 7px 4px 8px;
        border-radius: 24px;
        margin-right: 8px;
        font-size: 0.9em;
        box-shadow: 0 0 4px #676bff; }
      .hero__button__tiny:hover {
        background: #33334e; }
  .hero__tiny {
    font-weight: 500;
    font-size: 2.5em;
    text-transform: uppercase;
    color: #aca5b5;
    position: absolute;
    top: calc(100vh - 60px);
    margin: 0 auto;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    width: 175px;
    height: 55px; }
    .hero__tiny:after {
      content: "↓";
      position: absolute;
      right: 82px;
      top: 26px;
      animation: moving .5s ease alternate infinite; }
  .hero__bg {
    width: 100%;
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 110vh;
    max-height: 120vh;
    z-index: -1; }
    @media (max-width: 37.5em) {
      .hero__bg {
        filter: blur(3px);
        transform: rotate(90deg) translate(-38px, -388px) scale(1.6);
        transform-origin: top;
        width: 110%;
        top: 51%;
        left: 46%; } }
  .hero__link {
    color: rgba(252, 249, 255, 0.7);
    text-decoration: underline;
    transition: all .2s; }
    .hero__link:hover {
      color: white; }

.label-hidden {
  position: absolute;
  top: 20px;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  font-weight: 300;
  opacity: 0.0;
  cursor: text;
  transition: 0.2s ease all;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }
  .label-hidden:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 45%;
    height: 2px;
    width: 10px;
    visibility: hidden;
    background-color: white;
    ransition: 0.2s ease all; }

.hero__button, .hero__button-small {
  text-decoration: none;
  font-weight: 500;
  padding: 1.5rem 3.3rem;
  background-color: #292b3f;
  border-radius: 5rem;
  margin: 1rem 2rem;
  transition: all .2s;
  color: white;
  outline: none; }

.hero__button:hover, .hero__button-small:hover {
  background-color: #282430;
  cursor: pointer; }

.hero__button.not-allowed {
  cursor: not-allowed; }

.hero__button-small {
  padding: 1rem, 3.1rem; }

.hero__updates {
  display: flex;
  flex-direction: column;
  color: rgba(252, 249, 255, 0.7);
  position: relative; }
  .hero__updates__title {
    font-size: 4rem;
    margin: 1.5rem auto; }
  .hero__updates__label {
    font-size: 2.5rem;
    position: absolute;
    bottom: 10rem;
    align-self: center;
    transition: all .2s; }
  .hero__updates__input {
    width: 30rem;
    margin: auto;
    margin-top: 1.25rem;
    padding: 2.5rem 0 .5rem 0;
    font-size: 2.5rem;
    border: none;
    outline: none;
    color: white;
    border-bottom: 2px solid rgba(252, 249, 255, 0.7);
    background-color: transparent; }
    .hero__updates__input:focus {
      border-bottom: 2px solid #EBA471; }
      .hero__updates__input:focus + .hero__updates__label {
        transform: translateY(-25px); }
  .hero__updates__submit {
    font-size: 2rem;
    font-weight: 600;
    border: none;
    align-self: center;
    padding: 1.5rem 3.3rem;
    background-color: #292b3f;
    border-radius: 5rem;
    margin: 1.75rem 2rem;
    transition: all .2s;
    text-transform: uppercase;
    color: white;
    outline: none;
    cursor: pointer; }
    .hero__updates__submit:hover {
      background-color: #282430; }

@keyframes moving {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(3px); } }

.hero__stats {
  display: flex;
  padding: 2rem;
  color: white;
  font-size: 4rem;
  justify-content: center;
  align-items: center; }
  @media (max-width: 56.25em) {
    .hero__stats {
      font-size: 3.75rem; } }
  @media (max-width: 37.5em) {
    .hero__stats {
      font-size: 3.25rem;
      padding: 0; } }
  @media (max-width: 25em) {
    .hero__stats {
      font-size: 3rem; } }

#hackers, #projects, #hours {
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 0rem 0rem 10rem 0rem; }
  @media (max-width: 37.5em) {
    #hackers, #projects, #hours {
      margin-left: 2rem;
      margin-right: 2rem; } }

.mission {
  padding: 16rem 32rem 3rem;
  display: flex;
  flex-direction: column; }
  @media (max-width: 75em) {
    .mission {
      padding: 8rem 5rem; } }
  @media (max-width: 56.25em) {
    .mission {
      padding: 5rem 3rem; } }
  .mission__text {
    font-size: 2.5rem;
    font-family: "Space Mono", monospace;
    color: #EBA471;
    text-transform: uppercase;
    letter-spacing: 2px; }
    @media (max-width: 37.5em) {
      .mission__text {
        text-align: center; } }
  .mission__title {
    color: white;
    display: inline-block;
    margin: .5rem 0;
    font-size: 4rem;
    font-weight: 600; }
    @media (max-width: 37.5em) {
      .mission__title {
        text-align: center; } }
  .mission__statement {
    color: rgba(252, 249, 255, 0.7);
    font-size: 3rem; }
    @media (max-width: 37.5em) {
      .mission__statement {
        text-align: center; } }

.cta-container {
  padding: 6rem 14% 10rem 14%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  justify-content: center;
  grid-column-gap: 1rem; }
  @media (max-width: 75em) {
    .cta-container {
      padding: 5rem;
      grid-template-columns: 1fr; } }
  @media (max-width: 37.5em) {
    .cta-container {
      padding: 2rem; } }

.cta {
  min-height: 39rem;
  margin: 2rem 1rem;
  padding: 4rem 3rem 2rem;
  border-radius: 20px;
  background: #312D38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .cta__title {
    font-size: 3.2rem;
    font-weight: 600;
    color: white;
    margin: 5rem 0 1rem; }
  .cta__text {
    font-size: 2.75rem;
    color: white; }

.highlights-container {
  display: flex;
  flex-direction: column; }

.highlight {
  display: flex;
  align-items: center;
  padding: 2rem 32rem; }
  @media (max-width: 75em) {
    .highlight {
      flex-direction: column !important;
      padding: 2rem; } }
  .highlight.reverse {
    flex-direction: row-reverse; }
    .highlight.reverse .highlight__text {
      text-align: left; }
      @media (max-width: 75em) {
        .highlight.reverse .highlight__text {
          text-align: center;
          margin: 3rem 0 5rem 0; } }
    .highlight.reverse .highlight__image-container {
      margin: 0 0 0 4rem; }
      @media (max-width: 75em) {
        .highlight.reverse .highlight__image-container {
          margin: 0; } }
  .highlight__image-container {
    margin-right: 5rem;
    position: relative;
    width: 50rem; }
    @media (max-width: 75em) {
      .highlight__image-container {
        margin: 0; } }
    @media (max-width: 37.5em) {
      .highlight__image-container {
        width: 40rem;
        height: 30rem; } }
  .highlight__image {
    width: 50rem;
    filter: grayscale(100%);
    border-radius: 10px; }
    @media (max-width: 37.5em) {
      .highlight__image {
        width: 40rem; } }
  .highlight__overlay {
    height: 32.45rem;
    width: 100%;
    position: absolute;
    border-radius: 10px;
    background-image: linear-gradient(202.54deg, rgba(211, 167, 255, 0.3) 6.32%, rgba(9, 93, 255, 0.3) 82.62%);
    top: 0;
    left: 0;
    right: 0; }
    @media (max-width: 37.5em) {
      .highlight__overlay {
        height: 26rem; } }
  .highlight__text {
    text-align: right;
    color: white; }
    @media (max-width: 75em) {
      .highlight__text {
        text-align: center;
        margin: 3rem 0; } }
    .highlight__text__title {
      font-size: 4rem;
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap; }
      @media (max-width: 99em) {
        .highlight__text__title {
          white-space: normal; } }
    .highlight__text p {
      font-size: 2.4rem;
      color: rgba(252, 249, 255, 0.7); }

.sponsors__container {
  padding: 0rem 15rem 2rem 15rem;
  text-align: center; }
  @media (max-width: 75em) {
    .sponsors__container {
      padding: 2rem;
      margin-bottom: 0; } }
  @media (max-width: 37.5em) {
    .sponsors__container {
      margin-top: 0; } }

.sponsors__title {
  font-size: 4rem;
  text-transform: uppercase;
  color: white;
  margin-top: 2rem;
  margin-bottom: 5rem; }

.sponsors__logos.gigas {
  padding: 0;
  position: sticky;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-items: center;
  justify-content: center; }
  @media (max-width: 75em) {
    .sponsors__logos.gigas {
      padding-bottom: 0rem;
      margin-top: 0rem; } }
  @media (max-width: 56.25em) {
    .sponsors__logos.gigas {
      margin-top: -2.75rem; } }

.sponsors__logos.kilos {
  padding: 0;
  position: sticky;
  padding-bottom: 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
  flex: 0 0 10%; }
  @media (max-width: 75em) {
    .sponsors__logos.kilos {
      padding: 0rem 2rem 0rem 2rem; } }
  @media (max-width: 56.25em) {
    .sponsors__logos.kilos {
      padding: 0rem 2rem 0rem 2rem; } }

.sponsors__logos.centis {
  padding: 0;
  position: sticky;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  justify-content: center;
  padding-bottom: 4rem; }

.sponsors__logos.partners {
  padding: 0;
  position: sticky;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-items: center;
  justify-content: center;
  flex: 0 0 10%; }
  @media (max-width: 37.5em) {
    .sponsors__logos.partners {
      padding: 7.5rem 14% 1rem 14%;
      margin-top: -8rem; } }

.sponsors__giga {
  position: sticky;
  background-color: #312D38;
  margin: 1rem 1rem;
  padding: 5% 9.75% 5% 9.75%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .2s; }
  @media (max-width: 37.5em) {
    .sponsors__giga {
      padding: 5% .5% 5% .5%; } }
  .sponsors__giga:hover {
    background-color: #282430; }

.sponsors__kilo {
  background-color: #312D38;
  position: sticky;
  max-height: 19rem;
  margin: 1rem 1rem;
  padding: 10% 12.75% 10% 12.75%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .2s; }
  .sponsors__kilo:hover {
    background-color: #282430; }

.sponsors__centi {
  background-color: #312D38;
  position: sticky;
  max-height: 100px;
  margin: 1rem 1rem;
  padding: 18%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .2s; }
  .sponsors__centi:hover {
    background-color: #282430; }

.sponsors__partner {
  background-color: #312D38;
  position: sticky;
  max-height: 19rem;
  margin: 1rem 1rem;
  padding: 10% 12.75% 10% 12.75%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all .2s; }
  .sponsors__partner:hover {
    background-color: #282430; }

.faq-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 960px;
  margin: auto; }
  @media (max-width: 75em) {
    .faq-grid {
      grid-template-columns: 1fr; } }

.faq-container {
  padding: 10rem 15rem;
  text-align: center; }
  @media (max-width: 75em) {
    .faq-container {
      padding: 2rem; } }
  .faq-container__column {
    display: flex;
    flex-direction: column; }
  .faq-container__title {
    font-size: 4rem;
    text-transform: uppercase;
    color: white;
    margin-bottom: 5rem; }

.question {
  padding: 3rem;
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: white;
  text-align: left;
  background-color: #1C1921;
  border-radius: 20px; }
  .question__title {
    font-size: 2.7rem;
    font-weight: 600;
    text-transform: uppercase;
    display: block;
    margin-bottom: 1rem; }
  .question__link {
    text-decoration: underline;
    color: white; }

.question-bg {
  flex: 1;
  padding: .25rem .35rem;
  margin: 2rem;
  border: 4px solid #EBA471;
  border-radius: 20px; }

.team {
  padding: 10rem;
  text-align: center; }
  @media (max-width: 56.25em) {
    .team {
      padding: 5rem 2rem; } }
  .team__title {
    font-size: 4rem;
    text-transform: uppercase;
    color: white; }

.team__row {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.member {
  margin: 1rem;
  padding: 1rem 2rem;
  min-width: 20rem;
  max-width: 25rem;
  min-height: 30rem;
  background-color: #312D38;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .member__portrait {
    overflow: hidden;
    width: 135px;
    height: 135px;
    border-radius: 50%;
    margin: 2rem;
    position: relative; }
  .member__name {
    font-size: 2.6rem;
    white-space: nowrap;
    font-weight: 600;
    color: white;
    padding: .5rem 0; }
  .member__title {
    font-size: 1.8rem;
    color: rgba(252, 249, 255, 0.7); }
  .member__links {
    margin: 1.1rem 0; }
    .member__links img {
      margin: .25rem 0.9rem;
      height: 2.2rem; }
  .member__profile {
    width: 95%;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    background-size: cover;
    transform: translate(-50%, -50%); }

.footer {
  padding: 5rem 10rem;
  display: flex;
  justify-content: space-between; }
  @media (max-width: 37.5em) {
    .footer {
      padding: 5rem; } }
  .footer__copyright {
    font-size: 2rem;
    color: #8D84A1; }
    @media (max-width: 37.5em) {
      .footer__copyright {
        font-size: 1rem; } }

.schedule {
  max-width: 1160px;
  margin: auto;
  color: white;
  font-size: 3rem;
  padding: 8rem 4rem; }

.schedule__title {
  text-align: center;
  font-size: 5rem;
  font-weight: normal;
  text-transform: uppercase;
  margin-bottom: 3rem; }

.schedule__grid {
  display: grid;
  margin-top: 8rem;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center; }
  @media (max-width: 56.25em) {
    .schedule__grid {
      grid-template-columns: 1fr;
      grid-row-gap: 6rem; } }

.column-plus {
  position: absolute;
  top: -30px;
  left: -20px; }

.schedule__column {
  position: relative;
  max-width: 220px; }

.schedule__column__title {
  font-size: 4rem;
  font-weight: normal;
  margin-bottom: -1rem; }

.schedule__column__date {
  font-size: 2.5rem;
  font-weight: light;
  margin-left: 1rem; }

.schedule__column__content {
  padding-top: 2rem;
  font-size: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 2rem;
  align-items: center; }
  .schedule__column__content.large .schedule__item, .schedule__column__content.large .schedule__item--right {
    font-size: 3rem; }

.schedule__item, .schedule__item--right {
  font-size: 2.5rem; }

.schedule__item--right {
  margin-left: auto; }

.application {
  text-align: center;
  min-height: 100vh; }
  .application__logo {
    position: sticky;
    height: 16rem;
    cursor: pointer;
    top: 30px; }
    @media (max-width: 75em) {
      .application__logo {
        height: 18rem; } }
    @media (max-width: 56.25em) {
      .application__logo {
        height: 19rem; } }
    @media (max-width: 37.5em) {
      .application__logo {
        top: 25px;
        height: 18rem; } }
  .application__title {
    font-size: 9.5rem;
    margin-top: 18rem;
    color: white;
    cursor: default; }
    @media (max-width: 75em) {
      .application__title {
        margin-top: 15rem;
        font-size: 7.5rem; } }
  .application__select {
    text-align: center;
    position: relative;
    margin: 10rem 0 8rem 0; }
    @media (max-width: 56.25em) {
      .application__select {
        margin-top: 6rem; } }
    .application__select__title {
      display: block;
      color: white;
      font-size: 4rem;
      margin: 2rem; }

.appselect {
  height: 5rem;
  width: 18rem;
  margin: 1rem;
  background-color: #312D38;
  border-radius: 2rem;
  display: inline-flex;
  padding: 2rem 3.4rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .2s; }
  .appselect:hover {
    background-color: #3d3846; }
  .appselect.selected {
    background-color: white; }
    .appselect.selected .appselect__title {
      color: #312D38; }
  .appselect__title {
    font-size: 4rem;
    text-transform: uppercase;
    color: white; }

.form-container {
  max-width: 815px;
  background: #1e1c23;
  border: 4px solid #f7a167;
  margin: 0 auto;
  border-radius: 2rem;
  padding: 20px 60px; }
  @media (max-width: 75em) {
    .form-container {
      border: none;
      padding: 20px; } }
  .form-container__text {
    font-size: 3rem;
    color: rgba(252, 249, 255, 0.7);
    padding: 0 2rem;
    text-align: justify; }
    .form-container__text span {
      display: block;
      margin: 2rem auto; }
  .form-container__title {
    color: #EBA471;
    font-size: 5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 5rem 1rem 3rem 1rem; }
  .form-container__link {
    color: rgba(252, 249, 255, 0.7);
    text-decoration: underline;
    transition: all .2s; }
    .form-container__link:hover {
      color: white; }

.form__checkbox {
  color: white;
  padding: 0.5rem;
  font-size: 1.5rem; }
  .form__checkbox.small {
    font-size: 2rem;
    font-size: 3rem;
    color: rgba(252, 249, 255, 0.7);
    vertical-align: middle;
    padding-left: 1rem; }
  .form__checkbox a {
    color: inherit; }

.form__text {
  color: rgba(252, 249, 255, 0.7);
  font-size: 2rem;
  margin: 1rem;
  text-align: justify;
  cursor: default; }
  .form__text a {
    color: #EBA471; }

.form__submit {
  padding: 2rem 4rem;
  border: none;
  outline: none;
  background-color: #312D38;
  font-size: 3rem;
  color: white;
  border-radius: 3rem;
  margin: 1rem auto 4rem auto;
  cursor: pointer;
  transition: all .2s; }
  .form__submit:hover {
    font-size: 3.5rem; }

.form__group {
  display: grid;
  position: relative;
  width: max-content;
  margin: 1rem auto;
  color: rgba(252, 249, 255, 0.7);
  font-size: 3rem;
  letter-spacing: 1px;
  padding-top: 3rem; }
  .form__group:not(:first-child) {
    margin-top: 5rem; }
  @media (max-width: 75em) {
    .form__group {
      width: initial; } }
  .form__group__label {
    position: absolute;
    top: 5px;
    left: 0;
    font-size: 2rem;
    transition: all .15s; }
    .form__group__label.inactive {
      transform: translateY(30px); }
  .form__group__input {
    padding: 1rem 0;
    min-width: 45rem;
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    border-bottom: 1.5px solid #312D38; }
    @media (max-width: 56.25em) {
      .form__group__input {
        min-width: 30rem; } }
    @media (max-width: 37.5em) {
      .form__group__input {
        width: 85vw; } }
    .form__group__input:focus {
      border-bottom: 1.5px solid #EBA471; }
      .form__group__input:focus + .form__group__label {
        transform: translateY(0); }
  .form__group__file {
    padding: 2rem 4rem;
    border-radius: 3rem;
    background-color: #312D38;
    transition: background-color .2s;
    cursor: pointer; }
    .form__group__file:hover {
      background-color: #3d3846; }
  .form__group__charcount {
    position: absolute;
    font-size: 1rem;
    bottom: 10px;
    right: 10px; }
  .form__group__textarea {
    min-width: 45rem;
    background-color: transparent;
    outline: none;
    border: none;
    color: white;
    resize: vertical;
    margin-bottom: 3rem; }
    .form__group__textarea:focus + .form__group__label {
      transform: translateY(0); }

.gender, .race, .graduation, .hackathon, .shirt, .skill, .availability, .club {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10rem auto 4rem auto;
  color: white; }
  @media (max-width: 56.25em) {
    .gender, .race, .graduation, .hackathon, .shirt, .skill, .availability, .club {
      flex-direction: column; } }
  .gender__title, .race__title, .graduation__title, .hackathon__title, .shirt__title, .skill__title, .availability__title, .club__title {
    font-size: 4rem;
    font-weight: normal;
    margin-bottom: 2rem;
    margin-right: 2rem;
    text-align: left; }
    @media (max-width: 56.25em) {
      .gender__title, .race__title, .graduation__title, .hackathon__title, .shirt__title, .skill__title, .availability__title, .club__title {
        text-align: center; } }
  .gender .graduation__title, .race .graduation__title, .graduation .graduation__title, .hackathon .graduation__title, .shirt .graduation__title, .skill .graduation__title, .availability .graduation__title, .club .graduation__title {
    margin-right: 0; }
  .gender__select, .race__select, .graduation__select, .hackathon__select, .shirt__select, .skill__select, .availability__select, .club__select {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
    .gender__select__option, .race__select__option, .graduation__select__option, .hackathon__select__option, .shirt__select__option, .skill__select__option, .availability__select__option, .club__select__option {
      font-size: 3rem;
      padding: 3rem 4rem;
      background-color: #312D38;
      border-radius: 3rem;
      margin: 1rem;
      flex: 1;
      cursor: pointer;
      transition: background-color .2s; }
      .gender__select__option:hover, .race__select__option:hover, .graduation__select__option:hover, .hackathon__select__option:hover, .shirt__select__option:hover, .skill__select__option:hover, .availability__select__option:hover, .club__select__option:hover {
        background-color: #3d3846; }
      .gender__select__option.active, .race__select__option.active, .graduation__select__option.active, .hackathon__select__option.active, .shirt__select__option.active, .skill__select__option.active, .availability__select__option.active, .club__select__option.active {
        background: white;
        color: #312D38; }
    .gender__select .race__select__option, .race__select .race__select__option, .graduation__select .race__select__option, .hackathon__select .race__select__option, .shirt__select .race__select__option, .skill__select .race__select__option, .availability__select .race__select__option, .club__select .race__select__option {
      padding: 3rem 2rem; }
    .gender__select .hackathon__select__option, .race__select .hackathon__select__option, .graduation__select .hackathon__select__option, .hackathon__select .hackathon__select__option, .shirt__select .hackathon__select__option, .skill__select .hackathon__select__option, .availability__select .hackathon__select__option, .club__select .hackathon__select__option {
      white-space: nowrap; }
    .gender__select .graduation__select__option, .race__select .graduation__select__option, .graduation__select .graduation__select__option, .hackathon__select .graduation__select__option, .shirt__select .graduation__select__option, .skill__select .graduation__select__option, .availability__select .graduation__select__option, .club__select .graduation__select__option {
      flex: 0; }
  .gender .shirt__select, .race .shirt__select, .graduation .shirt__select, .hackathon .shirt__select, .shirt .shirt__select, .skill .shirt__select, .availability .shirt__select, .club .shirt__select {
    flex-wrap: nowrap;
    flex: 1; }
    @media (max-width: 56.25em) {
      .gender .shirt__select, .race .shirt__select, .graduation .shirt__select, .hackathon .shirt__select, .shirt .shirt__select, .skill .shirt__select, .availability .shirt__select, .club .shirt__select {
        flex-wrap: wrap; } }

.checkbox {
  height: 3rem;
  width: 3rem;
  border: 3px solid #EBA471;
  border-radius: 50%;
  position: relative;
  overflow: hidden; }
  .checkbox .check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #EBA471;
    height: 2rem;
    width: 2rem;
    border-radius: 50%; }

.avail {
  margin: 10rem auto 4rem auto;
  color: white; }
  .avail__title {
    font-size: 4rem;
    font-weight: normal;
    margin-bottom: 2rem;
    margin-right: 2rem;
    text-align: left; }
    @media (max-width: 56.25em) {
      .avail__title {
        text-align: center; } }
  .avail__select {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center; }
  .avail__col {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .avail__col__title {
      font-size: 3rem;
      padding: 2rem; }
    .avail__col__option {
      font-size: 3rem;
      padding: 3rem 4rem;
      background-color: #312D38;
      border-radius: 3rem;
      margin: 1rem;
      cursor: pointer;
      transition: background-color .2s; }
      .avail__col__option:hover {
        background-color: #3d3846; }
      .avail__col__option.active {
        background: white;
        color: #312D38; }

.live-hero {
  max-width: 1160px;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8rem;
  position: relative; }
  @media (max-width: 56.25em) {
    .live-hero {
      margin-top: 3rem; } }

.live-hero__left, .live-hero__right {
  position: fixed;
  top: 50px;
  min-height: 500px;
  height: 125vh;
  z-index: -2;
  opacity: .3; }

.live-hero__right {
  right: 0px;
  transform: scale(-1); }

.live-hero__left {
  left: 0px; }

.live-hero__logo {
  height: 15rem; }
  @media (max-width: 56.25em) {
    .live-hero__logo {
      height: 10rem; } }

.live-hero__title {
  color: white;
  font-size: 8rem;
  margin: 1rem 0; }
  @media (max-width: 56.25em) {
    .live-hero__title {
      font-size: 6rem; } }

.live-hero__social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 2rem 0 2rem;
  transform: scale(0.8); }
  .live-hero__social img {
    height: 6rem;
    margin: 1rem;
    transition: transform .2s; }
    @media (max-width: 99em) {
      .live-hero__social img {
        height: 5rem;
        margin: 0rem 2rem 1rem 2rem; } }
    @media (max-width: 37.5em) {
      .live-hero__social img {
        height: 4rem; } }
    .live-hero__social img:hover {
      transform: scale(1.1); }

.box {
  height: 30rem;
  min-width: 30rem;
  background-color: tomato;
  margin: 2rem; }

.announcements {
  margin: 3rem auto;
  margin-bottom: 9rem; }

.announcements__title {
  display: block;
  font-size: 5rem;
  color: #EBA471;
  margin-bottom: 2rem;
  text-align: center; }

.announcements-container {
  display: flex;
  width: 1160px !important;
  height: 340px !important;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  border-radius: 6px;
  background-color: #312D38; }

.announcement {
  min-width: 35rem;
  min-height: 35rem;
  background-color: #1C1921;
  position: relative;
  margin: 1.5rem; }
  .announcement__day, .announcement__time {
    position: absolute;
    top: 10px;
    color: white;
    font-size: 3rem; }
  .announcement__day {
    left: 10px; }
  .announcement__time {
    right: 10px; }
  .announcement__title {
    display: block;
    width: 80%;
    font-size: 4rem;
    color: #EBA471;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.links-container {
  display: flex;
  flex-wrap: wrap;
  border-radius: 100px;
  text-align: center;
  overflow: hidden;
  margin-bottom: 5rem;
  margin-top: 2rem;
  width: 100%; }
  @media (max-width: 56.25em) {
    .links-container {
      border-radius: 3rem; } }

.link__item {
  flex: 1;
  display: block;
  padding: 3rem 2rem;
  color: white;
  min-width: 10rem;
  font-size: 2.75rem;
  background-color: #312D38;
  transition: background-color .2s;
  border: 1px solid #1C1921; }
  .link__item:hover {
    background-color: #564f62; }

.live-schedule {
  max-width: 1160px;
  min-width: 1160px;
  margin: auto;
  text-align: center; }
  @media (max-width: 75em) {
    .live-schedule {
      display: none; } }

.live-schedule__title {
  font-family: "Space Mono", monospace;
  color: #EBA471;
  font-size: 5rem; }

.schedule-container {
  text-align: right;
  border-radius: 2rem;
  background-color: #312D38;
  margin: auto;
  margin-top: 2rem;
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 5rem 1fr;
  place-items: center; }
  .schedule-container span {
    font-size: 3rem;
    font-family: "Space Mono", monospace;
    color: #EBA471; }
    .schedule-container span.large {
      font-size: 5rem; }

.time-column {
  display: grid;
  align-self: stretch;
  grid-template-rows: repeat(auto-fit, 10rem);
  height: 515rem; }

span.time-day {
  font-size: 6rem; }

.schedule-column {
  display: flex;
  padding-top: 10rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  position: relative; }

.schedule-item {
  min-width: 30rem;
  border: 1px solid #EBA471;
  max-width: 30rem;
  min-height: 75px;
  background-color: #1C1921;
  position: absolute;
  text-align: left; }
  .schedule-item span.time {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 2rem; }
  .schedule-item span.title {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    font-size: 3rem;
    transform: translate(-50%, -50%); }

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10rem; }
  .login h1 {
    font-family: "Space Mono", monospace;
    color: #EBA471;
    font-size: 5rem; }

.colstats {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  justify-content: center;
  margin-bottom: 10em; }
  @media (max-width: 56.25em) {
    .colstats {
      flex-direction: column;
      align-items: center;
      margin-bottom: 4em; } }
  .colstats__metric {
    width: 241px;
    height: 160px;
    background: #4D4953;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 25px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transform: translateY(15px);
    transition: opacity 0.4s ease .27s, transform 0.6s ease .4s; }
    .colstats__metric:nth-child(2) {
      transition: opacity 0.4s ease .37s, transform 0.6s ease .5s; }
    .colstats__metric:nth-child(3) {
      transition: opacity 0.4s ease .47s, transform 0.6s ease .6s; }
    .colstats__metric__stat {
      font-weight: bold;
      font-size: 60px;
      text-align: center;
      color: #FCF9FF; }
      @media (max-width: 56.25em) {
        .colstats__metric__stat {
          font-size: 70px; } }
    .colstats__metric__describer {
      font-weight: bold;
      line-height: 27px;
      font-size: 14px;
      text-align: center;
      text-transform: uppercase;
      color: rgba(252, 249, 255, 0.7); }
      @media (max-width: 56.25em) {
        .colstats__metric__describer {
          line-height: 32px;
          font-size: 20px; } }
    @media (max-width: 56.25em) {
      .colstats__metric {
        width: 321px;
        height: 210px;
        margin: 25px 0; } }

.collage {
  margin-bottom: 10em;
  height: 660px;
  position: relative; }
  @media (max-width: 56.25em) {
    .collage {
      transform: scale(0.6) !important;
      margin-bottom: 4em; } }
  .collage img {
    border-radius: 8px;
    box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.45);
    position: absolute;
    opacity: 0;
    transform: translateY(15px); }
    .collage img:nth-child(1) {
      width: 451px;
      left: -130px;
      transition: opacity 0.4s ease .27s, transform 0.6s ease .4s; }
      @media (max-width: 56.25em) {
        .collage img:nth-child(1) {
          top: -180px; } }
    .collage img:nth-child(2) {
      width: 557px;
      right: -130px;
      top: 100px;
      z-index: 1;
      transition: opacity 0.4s ease .37s, transform 0.6s ease .5s; }
      @media (max-width: 56.25em) {
        .collage img:nth-child(2) {
          transition: opacity 0.4s ease 1.27s, transform 0.6s ease 1.4s; } }
    .collage img:nth-child(3) {
      width: 394px;
      top: 380px;
      left: -100px;
      transition: opacity 0.4s ease .47s, transform 0.6s ease .6s; }
      @media (max-width: 56.25em) {
        .collage img:nth-child(3) {
          transition: opacity 0.4s ease 2.27s, transform 0.6s ease 2.4s;
          top: 430px; } }

.displaytext {
  margin-bottom: 10em; }
  @media (max-width: 56.25em) {
    .displaytext {
      margin-bottom: 4em; } }
  .displaytext__header {
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    color: #FCF9FF;
    margin-bottom: .4em; }
  .displaytext__paragraph {
    font-weight: normal;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
    color: rgba(252, 249, 255, 0.7); }

.bigstat {
  position: relative;
  width: 640px;
  height: 640px;
  margin: auto auto 10em auto; }
  .bigstat a {
    text-decoration: underline;
    transition: color 0.2s ease; }
    .bigstat a:hover {
      color: rgba(252, 249, 255, 0.9); }
  @media (max-width: 56.25em) {
    .bigstat {
      margin-bottom: 4em;
      width: initial;
      height: 720px;
      margin: auto auto 10em auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; } }
  .bigstat__container {
    width: 640px;
    height: 640px;
    position: absolute;
    background: #4d3a6b;
    border-radius: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
    z-index: 1; }
    @media (max-width: 56.25em) {
      .bigstat__container {
        width: initial;
        height: initial;
        background: none;
        box-shadow: unset; } }
  .bigstat__intro, .bigstat__outro {
    font-weight: normal;
    font-size: 30px;
    width: 302px;
    text-align: center;
    color: rgba(252, 249, 255, 0.7);
    opacity: 0; }
  .bigstat__intro {
    transition: opacity 0.4s ease; }
  .bigstat__outro {
    transition: opacity 0.4s ease 2s; }
  .bigstat__bg {
    background: linear-gradient(0deg, #2e2a33 0, #4d3a6b 50%, #2e2a33 100%);
    position: absolute;
    width: 740px;
    left: -50px;
    height: 730px; }
    @media (max-width: 56.25em) {
      .bigstat__bg {
        width: 106%;
        left: unset; } }
  .bigstat__stat {
    font-weight: bold;
    font-size: 16em;
    margin: 30px 0;
    text-align: center;
    color: #6e5d96;
    padding: 10px 30px;
    border-radius: 6px;
    background: #6e5d96;
    transform: scale(0.95);
    transition: color 0.4s ease 1.37s, transform 0.6s ease 1.5s; }

.mediabuttons {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  display: flex;
  justify-content: center;
  margin-bottom: 10em; }
  @media (max-width: 56.25em) {
    .mediabuttons {
      flex-direction: column;
      align-items: center;
      margin-bottom: 4em; } }
  .mediabuttons__media {
    width: 280px;
    background: #4D4953;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    margin: 0 25px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
    opacity: 0;
    transform: translateY(15px);
    transition: opacity 0.4s ease .37s, transform 0.2s ease, background 0.5s ease; }
    .mediabuttons__media:hover {
      transform: translateY(-2px) !important;
      background: #534d5d; }
    .mediabuttons__media:nth-child(2) {
      transition: opacity 0.4s ease .27s, transform 0.2s ease, background 0.5s ease; }
    .mediabuttons__media:nth-child(3) {
      transition: opacity 0.4s ease .37s, transform 0.2s ease, background 0.5s ease; }
    .mediabuttons__media__body {
      padding: 2em;
      width: 100%;
      height: 110px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .mediabuttons__media__body__name {
        font-weight: bold;
        font-size: 20px;
        text-align: left;
        color: #FCF9FF; }
      .mediabuttons__media__body__source {
        text-align: right;
        font-weight: bold;
        line-height: 27px;
        font-size: 14px;
        text-transform: uppercase;
        color: rgba(252, 249, 255, 0.7); }
        .mediabuttons__media__body__source:before {
          content: "– "; }
    .mediabuttons__media__image {
      overflow: hidden;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .mediabuttons__media__image img {
        max-height: 220px; }
        @media (max-width: 56.25em) {
          .mediabuttons__media__image img {
            max-height: initial;
            max-width: 320px; } }
    @media (max-width: 56.25em) {
      .mediabuttons__media {
        width: 320px;
        height: 300px;
        margin: 25px 0; } }

.colbuttons {
  margin: auto auto 10em auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  @media (max-width: 56.25em) {
    .colbuttons {
      margin-bottom: 4em; } }
  .colbuttons__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 160px;
    background: #56505f;
    border-radius: 8px;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
    margin: 0 20px;
    opacity: 0;
    transform: translateY(15px);
    transition: opacity 0.4s ease .27s, transform 0.2s ease, background 0.5s ease; }
    .colbuttons__button:hover {
      transform: translateY(-2px) !important;
      background: #534d5d; }
    @media (max-width: 56.25em) {
      .colbuttons__button {
        margin: 0; }
        .colbuttons__button:nth-child(1) {
          border-radius: 8px 0 0 8px;
          box-shadow: -3px 2px 7px rgba(0, 0, 0, 0.25); }
        .colbuttons__button:nth-child(2) {
          border-radius: 0 8px 8px 0;
          border-left: 1px solid #6e6a71;
          box-shadow: 3px 2px 7px rgba(0, 0, 0, 0.25); } }
  .colbuttons__text {
    margin-top: 3px;
    font-weight: bold;
    line-height: 27px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    transform: color 0.2s ease; }
    .colbuttons__text:hover {
      color: #fcf9ff; }

.winnerhighlight__photo {
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius: 8px;
  box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.45);
  opacity: 0;
  transform: scale(1.1);
  transition: opacity 0.4s ease .27s, transform 0.6s ease; }

.winnerhighlight__team {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 56.25em) {
    .winnerhighlight__team {
      display: grid; } }
  .winnerhighlight__team__member {
    padding: 10px 16px 22px 16px;
    background: #56505f;
    border-radius: 8px;
    box-shadow: 0px 2px 27px rgba(0, 0, 0, 0.45);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin: 12px;
    position: relative;
    top: -70px;
    opacity: 0;
    transform: translateY(15px);
    transition: opacity 0.4s ease .27s, transform 0.6s ease .4s; }
    .winnerhighlight__team__member:nth-child(2) {
      transition: opacity 0.4s ease .37s, transform 0.6s ease .5s; }
    .winnerhighlight__team__member:nth-child(3) {
      transition: opacity 0.4s ease .47s, transform 0.6s ease .6s; }
    .winnerhighlight__team__member__photo {
      width: 100px;
      border-radius: 100px;
      padding: 16px 16px 10px 16px; }
    .winnerhighlight__team__member__name {
      margin: 0 0 3px;
      font-size: 3.2em;
      color: #fcfaff;
      font-weight: 500;
      text-align: center; }
    .winnerhighlight__team__member__contact {
      display: flex; }
      .winnerhighlight__team__member__contact a {
        margin: 0 4px 0 4px; }

.projecthighlight {
  width: 310px;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.4s ease .27s, transform 0.2s ease, background 0.5s ease; }
  .projecthighlight:hover {
    transform: translateY(-2px) !important;
    background: #534d5d; }
  .projecthighlight__body {
    background: #56505f;
    padding: 20px;
    border-radius: 0 0 8px 8px; }
    .projecthighlight__body__name {
      font-size: 4em;
      font-weight: 500;
      color: #fcfaff; }
    .projecthighlight__body__description {
      font-size: 2.5em;
      color: #ccc8ce; }
  .projecthighlight__image {
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-color: white; }

.sharebutton {
  margin: 0 8px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  background: #56505f;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 0.4s ease .27s, transform 0.2s ease, background 0.5s ease; }
  .sharebutton:hover {
    transform: translateY(-2px) !important;
    background: #534d5d; }
  @media (max-width: 56.25em) {
    .sharebutton {
      margin: 12px; } }
  .sharebutton__container {
    margin: auto auto 10em auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; }
    @media (max-width: 56.25em) {
      .sharebutton__container {
        margin-bottom: 4em;
        display: flex;
        flex-wrap: wrap; } }

.postlanding__container {
  background: #2D2A32;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  width: 640px;
  margin: 4.25em auto 10em auto;
  padding: 50px; }
  @media (max-width: 56.25em) {
    .postlanding__container {
      width: 90%;
      padding: 10% 3%;
      margin: 1% auto 5% auto; } }

.actionbutton__codeOfConduct {
  font-weight: 400;
  font-size: 2.4rem;
  padding: 2rem 2.5rem;
  background-color: #56505f;
  border-radius: 5rem;
  margin: 2.25rem 2.25rem 0;
  width: max-content;
  align-self: center;
  color: white;
  opacity: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  transform: translateY(15px);
  transition: opacity 0.4s ease .27s, transform 0.2s ease, 0.8s filter ease;
  background: linear-gradient(68deg, #711F73, #3d027c); }
  .actionbutton__codeOfConduct:hover {
    transform: translateY(-2px) !important; }
  .actionbutton__codeOfConduct:active {
    transform: translateY(0.05vh) !important;
    outline: none; }
  @media (max-width: 56.25em) {
    .actionbutton__codeOfConduct {
      margin: 1rem 0 1rem 0;
      padding: 1.5rem 2rem;
      font-size: 2rem; } }
  .actionbutton__codeOfConduct:hover {
    filter: hue-rotate(45deg); }
  .actionbutton__codeOfConduct:active {
    filter: hue-rotate(-60deg); }

.actionbutton__sponsorship {
  font-weight: 400;
  font-size: 2.4rem;
  padding: 2rem 2.5rem;
  background-color: #56505f;
  border-radius: 5rem;
  margin: 2.25rem 2.25rem 0;
  width: max-content;
  align-self: center;
  color: white;
  opacity: 0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  transform: translateY(15px);
  transition: opacity 0.4s ease .27s, transform 0.2s ease, 0.8s filter ease;
  background: linear-gradient(248deg, #711F73, #3d027c); }
  .actionbutton__sponsorship:hover {
    transform: translateY(-2px) !important; }
  .actionbutton__sponsorship:active {
    transform: translateY(0.05vh) !important;
    outline: none; }
  @media (max-width: 56.25em) {
    .actionbutton__sponsorship {
      margin: 1rem 0 1rem 0;
      padding: 1.5rem 2rem;
      font-size: 2rem; } }
  .actionbutton__sponsorship:hover {
    filter: hue-rotate(45deg); }
  .actionbutton__sponsorship:active {
    filter: hue-rotate(-60deg); }

.actionbutton__container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  margin-bottom: 2rem; }
  @media (max-width: 56.25em) {
    .actionbutton__container {
      margin-top: .75rem; } }

.hero {
  min-height: 70vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4rem 1rem; }
  @media (max-width: 56.25em) {
    .hero {
      min-height: 40vh; } }
  .hero__logo {
    height: 18rem;
    transform-origin: bottom center;
    animation: bellshake 0.5s 0.5s cubic-bezier(0.56, 0.7, 0.2, 0.77); }
    .hero__logo:hover {
      animation: colorFilter 0.2s cubic-bezier(0.1, 0.6, 0.3, 1) infinite, bellshake 0.5s cubic-bezier(0.56, 0.7, 0.2, 0.77); }

@-webkit-keyframes bellshake {
  0% {
    transform: rotate(0deg); }
  15% {
    transform: rotate(5deg); }
  30% {
    transform: rotate(-5deg); }
  45% {
    transform: rotate(4deg); }
  60% {
    transform: rotate(-4deg); }
  75% {
    transform: rotate(2deg); }
  85% {
    transform: rotate(-2deg); }
  92% {
    transform: rotate(1deg); }
  100% {
    transform: rotate(0); } }

@-moz-keyframes bellshake {
  0% {
    transform: rotate(0deg); }
  15% {
    transform: rotate(5deg); }
  30% {
    transform: rotate(-5deg); }
  45% {
    transform: rotate(4deg); }
  60% {
    transform: rotate(-4deg); }
  75% {
    transform: rotate(2deg); }
  85% {
    transform: rotate(-2deg); }
  92% {
    transform: rotate(1deg); }
  100% {
    transform: rotate(0); } }

@-ms-keyframes bellshake {
  .hero__logo 0% {
    transform: rotate(0deg); }
  .hero__logo 15% {
    transform: rotate(5deg); }
  .hero__logo 30% {
    transform: rotate(-5deg); }
  .hero__logo 45% {
    transform: rotate(4deg); }
  .hero__logo 60% {
    transform: rotate(-4deg); }
  .hero__logo 75% {
    transform: rotate(2deg); }
  .hero__logo 85% {
    transform: rotate(-2deg); }
  .hero__logo 92% {
    transform: rotate(1deg); }
  .hero__logo 100% {
    transform: rotate(0); } }

@keyframes bellshake {
  0% {
    transform: rotate(0deg); }
  15% {
    transform: rotate(5deg); }
  30% {
    transform: rotate(-5deg); }
  45% {
    transform: rotate(4deg); }
  60% {
    transform: rotate(-4deg); }
  75% {
    transform: rotate(2deg); }
  85% {
    transform: rotate(-2deg); }
  92% {
    transform: rotate(1deg); }
  100% {
    transform: rotate(0); } }

@-webkit-keyframes colorFilter {
  0% {
    filter: saturate(2) drop-shadow(0); }
  100% {
    filter: saturate(8) drop-shadow(0 0 0.2rem #711F73); } }

@-moz-keyframes colorFilter {
  0% {
    filter: saturate(2) drop-shadow(0); }
  100% {
    filter: saturate(8) drop-shadow(0 0 0.2rem #711F73); } }

@-ms-keyframes colorFilter {
  .hero__logo 0% {
    filter: saturate(2) drop-shadow(0); }
  .hero__logo 100% {
    filter: saturate(8) drop-shadow(0 0 0.2rem #711F73); } }

@keyframes colorFilter {
  0% {
    filter: saturate(2) drop-shadow(0); }
  100% {
    filter: saturate(8) drop-shadow(0 0 0.2rem #711F73); } }
    .hero__logo--collab {
      display: inline-flex;
      justify-content: center;
      width: 70px; }
    .hero__logo--sponsor {
      display: inline-flex; }
    @media (max-width: 56.25em) {
      .hero__logo {
        height: 14rem; } }
  .hero__title {
    font-size: 9.5rem;
    color: white;
    margin: 2rem auto 0rem auto;
    line-height: 1.2; }
    @media (max-width: 56.25em) {
      .hero__title {
        font-size: 7.25rem; } }
  .hero__text {
    color: rgba(252, 249, 255, 0.7);
    font-size: 3rem;
    font-weight: 500; }
    @media (max-width: 56.25em) {
      .hero__text {
        font-size: 2.5rem; } }
    .hero__text__alt {
      color: rgba(252, 249, 255, 0.7);
      font-size: 2.2rem;
      margin-top: 1rem;
      display: block; }
      @media (max-width: 56.25em) {
        .hero__text__alt {
          top: auto;
          box-shadow: 0 0 91px 24px #1d1922; } }
  .hero__background {
    position: sticky;
    top: 120px;
    z-index: 1;
    margin-top: 13vh;
    padding-bottom: 15vh;
    background: #1d1922;
    box-shadow: 0 0 45px 40px #1d1922;
    border-radius: 880px;
    width: 820px;
    padding: 30px 0; }
    @media (max-width: 56.25em) {
      .hero__background {
        width: 100%;
        margin-top: 20vh;
        margin-bottom: 5vh; } }
  .hero .deadline {
    grid-column-start: 1;
    grid-column-end: 3; }
  .hero__button-container {
    margin: 2rem;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap; }
    @media (max-width: 37.5em) {
      .hero__button-container {
        flex-direction: column; } }
  .hero__button {
    text-decoration: none;
    font-size: 2.75rem;
    font-weight: 400;
    padding: 1.5rem 3.3rem;
    background-color: #292b3f;
    border-radius: 5rem;
    margin: 1rem 1rem;
    transition: all .2s;
    color: white; }
    @media (max-width: 56.25em) {
      .hero__button {
        font-size: 2.5rem; } }
    @media (max-width: 37.5em) {
      .hero__button {
        font-size: 2rem; } }
    .hero__button:hover {
      background-color: #282430; }
    .hero__button.not-allowed {
      cursor: not-allowed; }
    .hero__button__cta {
      background-color: #8568eb !important;
      font-weight: 600 !important;
      text-transform: uppercase !important; }
      .hero__button__cta.orange {
        background-color: #AE9200  !important; }
        .hero__button__cta.orange:hover {
          background-color: #c8a700 !important; }
      .hero__button__cta.green {
        background-color: #00ae34  !important; }
        .hero__button__cta.green:hover {
          background-color: #00c83c !important; }
      .hero__button__cta:hover {
        background-color: #977ef1 !important; }
    .hero__button__tiny {
      color: white;
      font-size: 2em;
      text-decoration: none;
      background: #292940;
      padding: 0.7em 1em 0.7em 0.4em;
      border-radius: 2em;
      transition: background 0.5s ease;
      margin-bottom: 5em; }
      .hero__button__tiny:before {
        content: "NEW!";
        background: #676bff;
        padding: 4px 7px 4px 8px;
        border-radius: 24px;
        margin-right: 8px;
        font-size: 0.9em;
        box-shadow: 0 0 4px #676bff; }
      .hero__button__tiny:hover {
        background: #33334e; }
  .hero__tiny {
    font-weight: 500;
    font-size: 2.5em;
    text-transform: uppercase;
    color: #aca5b5;
    position: absolute;
    top: calc(100vh - 60px);
    margin: 0 auto;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    width: 175px;
    height: 55px; }
    .hero__tiny:after {
      content: "↓";
      position: absolute;
      right: 82px;
      top: 26px;
      animation: moving .5s ease alternate infinite; }
  .hero__bg {
    width: 100%;
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 110vh;
    max-height: 120vh;
    z-index: -2; }
    @media (max-width: 37.5em) {
      .hero__bg {
        filter: blur(3px);
        transform: rotate(90deg) translate(-38px, -388px) scale(1.6);
        transform-origin: top;
        width: 110%;
        top: 51%;
        left: 46%; } }
  .hero__link {
    color: rgba(252, 249, 255, 0.7);
    text-decoration: underline;
    transition: all .2s; }
    .hero__link:hover {
      color: white; }

.label-hidden {
  position: absolute;
  top: 20px;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  font-weight: 300;
  opacity: 0.0;
  cursor: text;
  transition: 0.2s ease all;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }
  .label-hidden:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 45%;
    height: 2px;
    width: 10px;
    visibility: hidden;
    background-color: white;
    ransition: 0.2s ease all; }

.hero__button, .hero__button-small {
  text-decoration: none;
  font-weight: 500;
  padding: 1.5rem 3.3rem;
  background-color: #292b3f;
  border-radius: 5rem;
  margin: 1rem 2rem;
  transition: all .2s;
  color: white;
  outline: none; }

.live-hero__left, .live-hero__right {
  z-index: -1; }

.hero__button:hover, .hero__button-small:hover {
  background-color: #282430;
  cursor: pointer; }

.hero__button.not-allowed {
  cursor: not-allowed; }

.hero__button-small {
  padding: 1rem, 3.1rem; }

.hero__updates {
  display: flex;
  flex-direction: column;
  color: rgba(252, 249, 255, 0.7);
  position: relative; }
  .hero__updates__title {
    font-size: 4rem;
    margin: 1.5rem auto; }
  .hero__updates__label {
    font-size: 2.5rem;
    position: absolute;
    bottom: 10rem;
    align-self: center;
    transition: all .2s; }
  .hero__updates__input {
    width: 30rem;
    margin: auto;
    margin-top: 1.25rem;
    padding: 2.5rem 0 .5rem 0;
    font-size: 2.5rem;
    border: none;
    outline: none;
    color: white;
    border-bottom: 2px solid rgba(252, 249, 255, 0.7);
    background-color: transparent; }
    .hero__updates__input:focus {
      border-bottom: 2px solid #EBA471; }
      .hero__updates__input:focus + .hero__updates__label {
        transform: translateY(-25px); }
  .hero__updates__submit {
    font-size: 2rem;
    font-weight: 600;
    border: none;
    align-self: center;
    padding: 1.5rem 3.3rem;
    background-color: #292b3f;
    border-radius: 5rem;
    margin: 1.75rem 2rem;
    transition: all .2s;
    text-transform: uppercase;
    color: white;
    outline: none;
    cursor: pointer; }
    .hero__updates__submit:hover {
      background-color: #282430; }

@keyframes moving {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(3px); } }

.hero__stats {
  display: flex;
  padding: 2rem;
  color: white;
  font-size: 4rem;
  justify-content: center;
  align-items: center; }
  @media (max-width: 56.25em) {
    .hero__stats {
      font-size: 3.75rem; } }
  @media (max-width: 37.5em) {
    .hero__stats {
      font-size: 3.25rem;
      padding: 0; } }
  @media (max-width: 25em) {
    .hero__stats {
      font-size: 3rem; } }

#hackers, #projects, #hours {
  margin-left: 3rem;
  margin-right: 3rem;
  padding: 0rem 0rem 10rem 0rem; }
  @media (max-width: 37.5em) {
    #hackers, #projects, #hours {
      margin-left: 2rem;
      margin-right: 2rem; } }

.subscriberinput {
  align-items: center;
  font-size: 2.2rem; }
  @media (max-width: 56.25em) {
    .subscriberinput {
      font-size: 2em; } }
  .subscriberinput__form {
    padding-bottom: 1.5rem; }
    @media (max-width: 56.25em) {
      .subscriberinput__form {
        margin-top: -.5em; } }
  .subscriberinput__container {
    position: relative;
    display: inline-block;
    top: -3px; }
    .subscriberinput__container_inputbox {
      box-sizing: border-box;
      padding-bottom: .15vh;
      align-items: center;
      text-align: center;
      background: transparent;
      border: 0;
      color: white;
      width: 13em;
      outline: 0; }
      .subscriberinput__container_inputbox:focus ~ label {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s ease-in .2s, opacity .2s; }
      .subscriberinput__container_inputbox:focus ~ span {
        width: 100%;
        transition: 0.4s ease-out;
        left: 0; }
      .subscriberinput__container_inputbox:hover ~ span {
        width: 100%;
        transition: 0.4s ease-out;
        left: 0; }
      .subscriberinput__container_inputbox:invalid {
        box-shadow: none; }
    .subscriberinput__container_inputboxfilled {
      box-sizing: border-box;
      padding-bottom: .15vh;
      align-items: center;
      text-align: center;
      background: transparent;
      border: 0;
      color: white;
      width: 13em;
      outline: 0; }
      .subscriberinput__container_inputboxfilled ~ span {
        width: 100%;
        transition: 0.4s ease-out;
        left: 0; }
    .subscriberinput__container_underline {
      display: block;
      position: absolute;
      bottom: -2px;
      left: 50%;
      width: 0;
      height: 2px;
      background-color: #711F73;
      transition: 0.4s ease-in; }
      @media (max-width: 56.25em) {
        .subscriberinput__container_underline {
          bottom: -0.95px; } }
    .subscriberinput__container_label {
      position: absolute;
      color: grey;
      font-weight: 500;
      visibility: visible;
      opacity: 1;
      transition: visibility 0s ease-in 0s, opacity .2s;
      cursor: text;
      top: 0;
      left: 4px; }
    .subscriberinput__container_labelhidden {
      position: absolute;
      color: grey;
      font-weight: 500;
      visibility: visible;
      opacity: 1;
      transition: visibility 0s ease-in 0s, opacity .2s;
      cursor: text;
      top: 0;
      left: 4px;
      visibility: hidden;
      opacity: 0; }
    .subscriberinput__container_submitbutton {
      font-size: 2.2rem;
      position: static;
      border-radius: 5rem;
      text-transform: uppercase;
      background: linear-gradient(-22deg, #711F73, #3d027c);
      border: 0;
      display: block;
      margin: auto;
      margin-top: -.25em;
      color: white;
      transform: translateY(-1px);
      transition: opacity 0.4s ease .27s, transform 0.2s ease, 0.8s filter ease;
      outline: none;
      padding: 1.25rem 2.75rem; }
      @media (max-width: 56.25em) {
        .subscriberinput__container_submitbutton {
          padding: 1.25rem 1.75rem;
          margin-top: -.4em; } }
      .subscriberinput__container_submitbutton:hover {
        transform: translateY(1px) !important;
        filter: hue-rotate(45deg); }
      .subscriberinput__container_submitbutton:active {
        transform: translateY(-0.15vh) !important;
        filter: hue-rotate(-60deg); }
